import React, {useState, useEffect, useRef} from 'react';
import './WheelSpinner.css';
import pilly from '../../assets/pilly.png';

export const rewardOptions = [
  {label: '1 reward point', value: 1, backgroundColor: '#f94144'},
  {label: '3 reward points', value: 3, backgroundColor: '#f3722c'},
  {label: '5 reward points', value: 4, backgroundColor: '#f8961e'},
  {label: '1 reward point', value: 1, backgroundColor: '#f9844a'},
  {label: '10 reward points', value: 10, backgroundColor: '#f9c74f'},
  {label: '1 reward point', value: 1, backgroundColor: '#90be6d'},
  {label: '3 reward points', value: 3, backgroundColor: '#43aa8b'},
  {label: '5 reward points', value: 5, backgroundColor: '#4d908e'},
  {label: '1 reward point', value: 1, backgroundColor: '#577590'},
  {label: '3 reward points', value: 3, backgroundColor: '#277da1'},
];

const ANIMATION_TIME = 11350;

export const WheelSpinner = ({
  onSpin,
  isDisabled,
}: {
  onSpin: (prizeIdx: number) => void;
  isDisabled: boolean;
}) => {
  const [finalRotationDegrees, setFinalRotationDegrees] = useState(0);
  const [hasStartedSpinning, setHasStartedSpinning] = useState(false);
  const [hasStoppedSpinning, setHasStoppedSpinning] = useState(false);
  const [isCurrentlySpinning, setIsCurrentlySpinning] = useState(false);
  const mustStopSpinning = useRef<boolean>(false);
  const [mustStartSpinning, setMustStartSpinning] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mustStartSpinning && !isCurrentlySpinning) {
      setIsCurrentlySpinning(true);
      setHasStartedSpinning(true);
      setHasStoppedSpinning(false);
      mustStopSpinning.current = true;
      setTimeout(() => {
        if (mustStopSpinning.current) {
          mustStopSpinning.current = false;
          setHasStartedSpinning(false);
          setHasStoppedSpinning(true);
          setMustStartSpinning(false);
          onSpin(prizeNumber);
        }
      }, ANIMATION_TIME);

      const degreesPerPrize = 360 / rewardOptions.length;
      const initialRotation = 43 + degreesPerPrize / 2;
      const randomDifference = (-1 + Math.random() * 2) * degreesPerPrize * 0.35;
      const prizeRotation =
        degreesPerPrize * (rewardOptions.length - prizeNumber) - initialRotation + randomDifference;
      const finalRotationDegreesCalculated =
        rewardOptions.length - prizeNumber > rewardOptions.length / 2
          ? -360 + prizeRotation
          : prizeRotation;

      setFinalRotationDegrees(finalRotationDegreesCalculated);
      if (containerRef.current) {
        containerRef.current.style.setProperty(
          '--finalRotationDegrees',
          `${finalRotationDegreesCalculated + 1440}deg`,
        );
      }
    }
  }, [mustStartSpinning]);

  useEffect(() => {
    if (hasStoppedSpinning) {
      setIsCurrentlySpinning(false);
      if (containerRef.current) {
        containerRef.current.style.setProperty(
          '--startRotationDegrees',
          `${finalRotationDegrees}deg`,
        );
        containerRef.current.style.setProperty(
          '--endRotationDegrees',
          `${finalRotationDegrees + 360}deg`,
        );
      }
    }
  }, [hasStoppedSpinning]);

  const onStartSpinning = () => {
    setPrizeNumber(Math.floor(Math.random() * rewardOptions.length));
    setMustStartSpinning(true);
  };

  return (
    <div className={'wheel'} ref={containerRef}>
      <div className={'wheel__innerContainer'}>
        <div className={`wheel__rotation ${hasStartedSpinning ? 'wheel__rotation--anim' : ''}`}>
          <WheelCanvas />
        </div>
        <img className={'wheel__img'} src={pilly} alt="" />
      </div>
      <button className={'wheel__btn'} disabled={isDisabled} onClick={onStartSpinning}>
        SPIN
      </button>
    </div>
  );
};

const WheelCanvas = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvasRef.current?.getContext('2d') as CanvasRenderingContext2D;
    if (!ctx) return;
    ctx.clearRect(0, 0, 500, 500);
    ctx.strokeStyle = 'transparent';
    ctx.lineWidth = 0;
    ctx.font = `bold 40px Helvetica, Arial`;

    const arc = Math.PI / (rewardOptions.length / 2);
    const outsideRadius = canvas.width / 2 - 10;
    const textRadius = (outsideRadius * 60) / 100;
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    for (let i = 0; i < rewardOptions.length; i++) {
      const angle = i * arc;
      ctx.fillStyle = rewardOptions[i].backgroundColor;

      ctx.beginPath();
      ctx.arc(centerX, centerY, outsideRadius, angle, angle + arc, false);
      ctx.arc(centerX, centerY, 0, angle + arc, angle, true);
      ctx.stroke();
      ctx.fill();
      ctx.save();

      ctx.strokeStyle = 'black';
      ctx.lineWidth = 6;
      for (let j = 0; j < rewardOptions.length; j++) {
        const radiusAngle = j * arc;
        ctx.beginPath();
        ctx.moveTo(centerX + Math.cos(radiusAngle), centerY + Math.sin(radiusAngle));
        ctx.lineTo(
          centerX + (outsideRadius - 1) * Math.cos(radiusAngle),
          centerY + (outsideRadius - 1) * Math.sin(radiusAngle),
        );
        ctx.closePath();
        ctx.stroke();
      }

      ctx.strokeStyle = 'rgb(0,0,50)';
      ctx.lineWidth = 6;
      ctx.beginPath();
      ctx.arc(centerX, centerY, outsideRadius - ctx.lineWidth / 2, 0, 2 * Math.PI);
      ctx.closePath();
      ctx.stroke();

      ctx.strokeStyle = 'rgb(0,0,50)';
      ctx.lineWidth = 0;
      ctx.beginPath();
      ctx.arc(centerX, centerY, ctx.lineWidth / 2 - 1, 0, 2 * Math.PI);
      ctx.closePath();
      ctx.stroke();

      ctx.fillStyle = 'black';
      ctx.translate(
        centerX + Math.cos(angle + arc / 2) * textRadius,
        centerY + Math.sin(angle + arc / 2) * textRadius,
      );
      const text = rewardOptions[i].label;
      const textRotationAngle = angle + arc / 2;
      ctx.rotate(textRotationAngle);
      ctx.fillText(text, -ctx.measureText(text).width / 2, 40 / 2.7);
      ctx.restore();
    }
  }, [canvasRef]);

  return <canvas className={'wheel__canvas'} ref={canvasRef} width={'900'} height={'900'} />;
};
