import {InitialSprite, Stats} from './models';
import * as PIXI from 'pixi.js';

export class GameUtils {
  static async initSpriteSheet(resource: string, sprites: InitialSprite[]) {
    const texture = await PIXI.Texture.from(resource);

    const textures: {[key: string]: PIXI.Texture<PIXI.Resource>} = {};
    for (const sprite of sprites) {
      try {
        const frame = new PIXI.Rectangle(sprite.x, sprite.y, sprite.width, sprite.height);
        textures[sprite.name] = new PIXI.Texture(texture.baseTexture, frame);
      } catch (e) {
        console.error(e);
      }
    }
    return textures;
  }

  static rectIntersect(a: PIXI.Sprite, b: PIXI.Sprite, padding = 20) {
    const box1 = a.getBounds();
    box1.x += padding;
    box1.width -= padding * 2;
    box1.y += padding;
    box1.height -= padding * 2;

    const box2 = b.getBounds();
    return (
      box1.x + box1.width > box2.x &&
      box1.x < box2.x + box2.width &&
      box1.y + box1.height > box2.y &&
      box1.y < box2.y + box2.height
    );
  }

  static tipIntersect(a: PIXI.Sprite, b: PIXI.Sprite, padding = 10) {
    const box1 = a.getBounds();
    const box2 = b.getBounds();
    return Math.abs(box1.x + box1.width - box2.x) < padding;
  }

  static lerp(start: number, end: number, time: number) {
    return (1 - time) * start + time * end;
  }

  static get midnight() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0);
    tomorrow.setMinutes(0);
    tomorrow.setSeconds(0);
    tomorrow.setMilliseconds(0);
    return tomorrow.getTime();
  }

  static get blankStats(): Stats {
    return {
      highScoreAllTime: 0,
      highScoreToday: 0,
      gamesPlayedToday: 0,
      gamesPlayedAllTime: 0,
      totalScoreAllTime: 0,
      totalScoreToday: 0,
      spinsToday: 0,
      rewardVault: 0,
    };
  }

  static updateStatsAfterSpin(points: number): Stats {
    localStorage.setItem('lastPlayed', Date.now().toString());
    const currentStatsLS = localStorage.getItem('statistics');
    const newStats: Stats = currentStatsLS ? JSON.parse(currentStatsLS) : this.blankStats;

    newStats.spinsToday++;
    newStats.rewardVault += points;

    localStorage.setItem('statistics', JSON.stringify(newStats));
    return newStats;
  }

  static updateStats(score: number, trackGamesPlayed: boolean): Stats {
    localStorage.setItem('lastPlayed', Date.now().toString());
    const currentStatsLS = localStorage.getItem('statistics');
    const newStats: Stats = currentStatsLS ? JSON.parse(currentStatsLS) : this.blankStats;

    if (trackGamesPlayed) {
      newStats.gamesPlayedToday++;
      newStats.gamesPlayedAllTime++;
    }
    newStats.totalScoreToday += score;
    newStats.totalScoreAllTime += score;
    if (score > newStats.highScoreToday) {
      newStats.highScoreToday = score;
    }
    if (score > newStats.highScoreAllTime) {
      newStats.highScoreAllTime = score;
    }

    localStorage.setItem('statistics', JSON.stringify(newStats));
    return newStats;
  }

  static getInitialStats(): Stats {
    const statsLS = localStorage.getItem('statistics');
    if (!statsLS) {
      return this.blankStats;
    }
    const stats = JSON.parse(statsLS) as Stats;
    if (this.isSameDay()) {
      return stats;
    } else {
      const newStats = {
        ...this.blankStats,
        highScoreAllTime: stats.highScoreAllTime,
        gamesPlayedAllTime: stats.gamesPlayedAllTime,
        totalScoreAllTime: stats.totalScoreAllTime,
      };
      localStorage.setItem('statistics', JSON.stringify(newStats));
      return newStats;
    }
  }

  static isSameDay() {
    const lastPlayedLS = localStorage.getItem('lastPlayed');
    if (!lastPlayedLS) return false;
    const today = new Date();
    const lastPlayed = new Date(parseInt(lastPlayedLS));
    return (
      today.getDate() === lastPlayed.getDate() &&
      today.getMonth() === lastPlayed.getMonth() &&
      today.getFullYear() === lastPlayed.getFullYear()
    );
  }
}
