import {CSSTransition} from 'react-transition-group';
import './ModalContainer.css';
import {CSSProperties, ReactNode, useEffect, useState} from 'react';

export const ModalContainer = ({
  show,
  close,
  children,
  outerStyle,
}: {
  show: boolean;
  close?: () => void;
  children: ReactNode;
  outerStyle?: CSSProperties;
}) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    setIsShown(show);
  }, [show]);

  return (
    <>
      <CSSTransition
        in={isShown}
        timeout={300}
        className={'modal__backdrop'}
        classNames={'modal__backdrop'}
        unmountOnExit
      >
        <div />
      </CSSTransition>
      <CSSTransition in={isShown} timeout={300} classNames="modal__container" unmountOnExit>
        <div
          className={'modal__outer'}
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              close?.();
            }
          }}
        >
          <div className={'modal__frame'} style={outerStyle ?? {}}>
            <div className={'modal__frame-inner'}>
              <div className={'modal__inner'} style={{paddingTop: close ? '2.5rem' : '1.25rem'}}>
                {!!close && (
                  <button className={'modal__closeBtn'} onClick={close}>
                    <svg viewBox="0 0 16 16">
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </button>
                )}
                {children}
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
