import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Game, Settings} from './game/game';
import {GameUtils} from './game/gameUtils';
import {GameOverModal} from './components/GameOverModal/GameOverModal';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {isMobileBrowser} from './utils/isMobileBrowser';

const DAILY_LIMIT = 3;
const CHEAT_CODE = 'flap123';

export const App = () => {
  const outerContainer = useRef<HTMLDivElement>(null);
  const gameContainer = useRef<HTMLDivElement>(null);
  const scoreHUD = useRef<HTMLDivElement>(null);
  const gameState = useRef<Game>();
  const [score, setScore] = useState(0);
  const [isGameOverModalShown, setIsGameOverModalShown] = useState(false);
  const [stats, setStats] = useState(() => GameUtils.getInitialStats());
  const dailyLimit = useRef(DAILY_LIMIT);
  const cheatCodeMatch = useRef(0);
  const [cheatCodeActivated, setCheatCodeActivated] = useState(false);
  const isMobile = useMemo(() => isMobileBrowser(), []);
  const [isStarted, setIsStarted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      window.location.reload();
    }, GameUtils.midnight - Date.now());
  }, []);

  const checkForCheatCode = useCallback((key: string) => {
    if (key !== CHEAT_CODE[cheatCodeMatch.current]) {
      cheatCodeMatch.current = 0;
      return;
    }
    cheatCodeMatch.current++;
    if (cheatCodeMatch.current !== CHEAT_CODE.length) return;
    if (dailyLimit.current === -1) {
      setCheatCodeActivated(false);
      dailyLimit.current = DAILY_LIMIT;
    } else {
      setCheatCodeActivated(true);
      dailyLimit.current = -1;
    }
    cheatCodeMatch.current = 0;
  }, []);

  useEffect(() => {
    const initializeGame = () => {
      if (!gameContainer.current) return;
      const containerRect = gameContainer.current.getBoundingClientRect();
      gameState.current = new Game(
        containerRect.width,
        containerRect.height,
        gameContainer.current,
        dailyLimit.current === -1 ? false : stats.gamesPlayedToday >= dailyLimit.current,
      );
      gameState.current.onUpdateScore = (score: number) => {
        if (gameState.current?.isStarted) {
          setIsStarted(true);
        }
        setScore(score);
      };
      gameState.current.onUpdateStats = (score: number) => {
        const newStats = GameUtils.updateStats(score, dailyLimit.current !== -1);
        setStats(newStats);
        if (dailyLimit.current !== -1 && newStats.gamesPlayedToday >= dailyLimit.current) {
          gameState.current?.onGameOver();
          setTimeout(() => setIsGameOverModalShown(true), 1000);
        }
      };
      if (dailyLimit.current !== -1 && stats.gamesPlayedToday >= dailyLimit.current) {
        setIsGameOverModalShown(true);
      }
    };

    const onKeyDown = (e: KeyboardEvent) => {
      if (gameState.current?.isGameOver) return;
      if (e.key === ' ') {
        gameState.current?.onPress();
      }
      checkForCheatCode(e.key);
    };

    const onResize = () => {
      if (scoreHUD.current) {
        if (gameContainer.current) {
          scoreHUD.current.style.top = `calc(${
            gameContainer.current.getBoundingClientRect().top + 'px'
          } + 5%)`;
        } else {
          scoreHUD.current.style.top = '5%';
        }
      }
      if (gameContainer.current) {
        const containerRect = gameContainer.current.getBoundingClientRect();
        gameState.current?.resize(containerRect.width, containerRect.height);
      }
    };

    const onTouch = () => {
      gameState.current?.onPress();
    };

    initializeGame();
    onResize();

    if (outerContainer.current) {
      outerContainer.current.addEventListener('touchstart', onTouch);
    }

    document.addEventListener('keydown', onKeyDown);
    window.addEventListener('resize', onResize);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('resize', onResize);
      if (outerContainer.current) {
        outerContainer.current.removeEventListener('touchstart', onTouch);
      }
    };
  }, []);

  const onUpdateStatsAfterSpin = useCallback((points: number) => {
    setStats(GameUtils.updateStatsAfterSpin(points));
  }, []);

  return (
    <>
      <div className={'game-outer-container'} ref={outerContainer}>
        <div className={'transparent-hud'} style={{left: 0}}>
          high score: {stats.highScoreAllTime}
        </div>
        <div className={'score-hud'} ref={scoreHUD}>
          {score}
        </div>
        <div className={'transparent-hud'} style={{right: 0}}>
          tries remaining: {cheatCodeActivated ? '∞' : DAILY_LIMIT - stats.gamesPlayedToday}
        </div>
        <div ref={gameContainer} className={'game-container'} />
      </div>
      {score >= Settings.maxPipes && isStarted && (
        <div className={'hud-subtitle'}>It's time to take Pilly home!</div>
      )}
      {!isStarted && (
        <div className={'hud-subtitle'}>
          Get Pilly to the lowest prices.{' '}
          {isMobile ? 'Tap anywhere to start!' : 'Press the space bar to start!'}
        </div>
      )}
      <GameOverModal
        show={isGameOverModalShown}
        stats={stats}
        updateStats={onUpdateStatsAfterSpin}
      />
      <ToastContainer
        position={'top-center'}
        autoClose={2000}
        hideProgressBar
        closeButton={false}
        style={{
          top: '1em',
        }}
        toastClassName={'toaster-toast'}
        className={'toaster-container'}
      />
    </>
  );
};
