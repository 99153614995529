import * as PIXI from 'pixi.js';
import spriteSheet from '../assets/spritesheet.png';
import {InitialSprite, SettingsProps, Texture} from './models';
import {GameUtils} from './gameUtils';

const sprites: InitialSprite[] = [
  {name: 'background', x: 0, y: 0, width: 144, height: 192},
  {name: 'pipe', x: 145, y: 0, width: 26, height: 192},
  {name: 'finalPipe', x: 189, y: 0, width: 30, height: 192},
  {name: 'pill1', x: 171, y: 86, width: 18, height: 25},
  {name: 'pill2', x: 171, y: 110, width: 18, height: 25},
  {name: 'pill3', x: 171, y: 135, width: 18, height: 25},
  {name: 'ground', x: 172, y: 13, width: 16, height: 72},
  {name: 'doctor1', x: 218, y: 5, width: 26, height: 38},
  {name: 'doctor2', x: 218, y: 40, width: 26, height: 39},
  {name: 'doctor3', x: 218, y: 80, width: 26, height: 40},
];

// const scale = window.devicePixelRatio;
const scale = 1;

export const Settings: SettingsProps = {
  playerFallSpeed: 8,
  playerHorizontalPosition: 100,
  playerVerticalPosition: 200,
  playerMaxVelocity: -3,
  pipeWidth: 80,
  groundHeight: 100,
  pipeHeight: 650,
  playerGravity: 0.45,
  minPipeHeight: 50,
  pipeVerticalGap: 220,
  gameSpeed: 6,
  flap: 15,
  maxPipes: 100,
};

class FlappySprite extends PIXI.Sprite {
  constructor(...args: any[]) {
    super(...args);
    this.scale.set(scale);
  }
}

class Ground extends PIXI.TilingSprite {
  constructor(texture: Texture) {
    super(texture, Settings.width, Settings.groundHeight);
    this.tileScale.set(scale * 2);
    this.position.x = 0;
    this.position.y = Settings.skyHeight!;
  }
}

class Background extends FlappySprite {
  constructor(texture: Texture) {
    super(texture);
    this.position.x = 0;
    this.position.y = 0;
    this.width = Settings.width!;
    this.height = Settings.height!;
  }
}

class Price extends PIXI.Text {
  constructor(text: string, x: number, y: number) {
    super(
      text,
      new PIXI.TextStyle({
        fill: 'white',
        fontWeight: 600 as any,
      }),
    );
    this.height = 30;
    this.position.x = x - this.width / 2;
    this.position.y = y - this.height / 2;
  }

  animate() {
    for (let i = 0; i <= 100; i++) {
      this.style.fill = '#ff6a00';
      this.style.fontSize = 30;
      setTimeout(() => {
        this.alpha = GameUtils.lerp(1, 0, i / 100);
      }, i * 5);
    }
  }
}

type PipeGroup = {
  upper: number;
  lower: number;
  pipe: Pipe;
  pipe2?: Pipe;
  scored?: boolean;
  price: Price;
};

class PipeContainer extends PIXI.Container {
  pipes: PipeGroup[] = [];
  pipeIndex = 0;
  pipeTexture: Texture;
  finalPipeTexture: Texture;
  children = [];
  finalPipe = false;
  onAddDoctor?: (x: number, y: number) => Doctor;
  doctor?: Doctor;

  constructor(pipeTexture: Texture, finalPipeTexture: Texture) {
    super();
    this.pipeTexture = pipeTexture;
    this.finalPipeTexture = finalPipeTexture;
    this.position.x = Settings.width! + Settings.pipeWidth / 2;
  }

  tryAddingNewPipe = () => {
    if (!this.pipes.length) return;
    const {pipe} = this.pipes[this.pipes.length - 1];
    if (-pipe.position.x >= Settings.pipeHorizontalGap!) {
      if (this.pipes.length >= Settings.maxPipes) {
        if (!this.finalPipe) {
          this.addFinalPipe();
        }
      } else {
        this.addNewPipe();
      }
    }
  };

  moveAll = () => {
    let score = 0;
    for (let i = 0; i < this.pipes.length; i++) {
      this.move(i);
      if (this.tryScoringPipe(i)) {
        score += 1;
      }
    }
    if (this.doctor) {
      this.doctor.position.x -= Settings.gameSpeed;
    }
    return score;
  };

  tryScoringPipe = (idx: number) => {
    const group = this.pipes[idx];

    if (!group.scored && this.toGlobal(group.pipe.position).x < Settings.playerHorizontalPosition) {
      group.scored = true;
      group.price.animate();
      return true;
    }
    return false;
  };

  move = (idx: number) => {
    const {pipe, pipe2, price} = this.pipes[idx];
    pipe.position.x -= Settings.gameSpeed;
    price.position.x -= Settings.gameSpeed;
    if (pipe2) {
      pipe2.position.x -= Settings.gameSpeed;
    }
  };

  addFinalPipe = () => {
    const pipe = new Pipe(
      this.finalPipeTexture,
      Settings.pipeWidth * 1.5,
      Settings.pipeHeight * 1.5,
    );
    pipe.rotation = -Math.PI / 2;

    pipe.position.y = Math.abs(Settings.skyHeight! - pipe.height);
    pipe.position.x = Settings.playerHorizontalPosition * 15 + pipe.height;
    const upper = pipe.position.y + pipe.height / 2;
    const lower = pipe.position.y + pipe.height / 2 + Settings.pipeVerticalGap;

    const price = new Price('$0', pipe.position.x - pipe.height / 2 - 30, pipe.position.y);

    const pipeGroup: PipeGroup = {
      upper,
      lower,
      pipe,
      price,
    };

    this.doctor = this.onAddDoctor?.(pipe.position.x - pipe.height / 3, pipe.position.y - 50);
    if (this.doctor) {
      this.addChild(this.doctor);
    }

    this.addChild(price);
    this.addChild(pipe);
    this.pipes.push(pipeGroup);
    this.tryRemovingLastGroup();
    this.finalPipe = true;
  };

  addNewPipe = () => {
    const pipe = new Pipe(this.pipeTexture);
    const pipe2 = new Pipe(this.pipeTexture);
    pipe.rotation = Math.PI;

    const maxPosition =
      Settings.skyHeight! - Settings.minPipeHeight - Settings.pipeVerticalGap - pipe.height / 2;
    const minPosition = -(pipe.height / 2 - Settings.minPipeHeight);

    pipe.position.y = Math.floor(Math.random() * (maxPosition - minPosition + 1) + minPosition);

    pipe2.position.y = pipe.height + pipe.position.y + Settings.pipeVerticalGap;
    if (this.pipes.length === 0) {
      pipe.position.x = 15;
      pipe2.position.x = 15;
    } else {
      pipe.position.x = 0;
      pipe2.position.x = 0;
    }
    const upper = pipe.position.y + pipe.height / 2;
    const lower = pipe.position.y + pipe.height / 2 + Settings.pipeVerticalGap;

    const price = new Price(
      `$${Settings.maxPipes - this.pipes.length}`,
      this.pipes.length === 0 ? 15 : 0,
      upper + Settings.pipeVerticalGap / 2,
    );

    const pipeGroup: PipeGroup = {
      upper,
      lower,
      pipe,
      pipe2,
      price,
    };

    this.addChild(price);
    this.addChild(pipe);
    this.addChild(pipe2);
    this.pipes.push(pipeGroup);
    this.tryRemovingLastGroup();
  };

  tryRemovingLastGroup = () => {
    if (this.pipes[0].pipe.position.x + Settings.pipeWidth / 2 > Settings.width!) {
      this.pipes.shift();
    }
  };

  getX = (idx: number) => {
    const {pipe} = this.pipes[idx];
    return this.toGlobal(pipe.position).x;
  };

  restart = () => {
    this.pipeIndex = 0;
    this.pipes = [];
    this.children = [];
    this.finalPipe = false;
  };
}

class Pipe extends FlappySprite {
  constructor(texture: Texture, width?: number, height?: number) {
    super(texture);
    this.width = width ?? Settings.pipeWidth;
    this.height = height ?? Settings.pipeHeight;
    this.anchor.set(0.5);
    if (!width && Game.isSmall) {
      this.width *= 0.9;
    }
  }
}

class Doctor extends PIXI.AnimatedSprite {
  constructor(textures: Texture[], x: number, y: number) {
    super(textures);
    this.animationSpeed = 0.08;
    this.width = 77 * scale;
    this.height = 99 * scale;
    this.position.x = x - this.width;
    this.position.y = y - this.height;
    this.play();
  }
}

class Pilly extends PIXI.AnimatedSprite {
  speedY: number;
  rate: number;

  constructor(textures: Texture[]) {
    super(textures);
    this.animationSpeed = 0.2;
    this.anchor.set(0.5);
    if (Game.isSmall) {
      this.width = 52;
      this.height = 71;
    } else {
      this.width = 70;
      this.height = 90;
    }

    this.speedY = Settings.playerFallSpeed;
    this.rate = Settings.playerGravity;

    this.restart();
  }

  spinOut = (onFinish: () => void) => {
    setTimeout(() => {
      for (let i = 0; i <= 100; i++) {
        setTimeout(() => {
          this.rotation = (Math.PI / i) * 10;
          this.alpha = GameUtils.lerp(1, 0, i / 100);
          if (i === 100) {
            onFinish();
          }
        }, i * 10);
      }
    }, 400);
  };

  reset = () => {
    this.alpha = 1;
    this.rotation = 0;
    this.position.x = Settings.playerHorizontalPosition;
    this.position.y = Settings.playerVerticalPosition;
  };

  restart = () => {
    this.reset();
    this.play();
  };

  updateGravity = () => {
    this.position.y -= this.speedY;
    this.speedY -= this.rate;
    this.rotation = Math.min(
      Math.PI / 4,
      Math.max(-Math.PI / 2, (Settings.flap + this.speedY) / Settings.flap),
    );
  };
}

export class Game {
  static isSmall = false;
  stopAnimating = true;
  isStarted = false;
  isDead = false;
  score = 0;
  app: PIXI.Application;
  textures: {[p: string]: Texture} = {};
  background?: Background;
  pipeContainer?: PipeContainer;
  ground?: Ground;
  pilly?: Pilly;
  onUpdateScore?: (score: number) => void;
  onUpdateStats?: (score: number) => void;
  isGameOver = false;
  isAnimatingFinal = false;

  constructor(
    width: number,
    height: number,
    parentElement: HTMLDivElement,
    alreadyGameOver: boolean,
  ) {
    if (window.innerWidth < 500) {
      Game.isSmall = true;
      Settings.gameSpeed = 5;
    }
    PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST;

    this.app = new PIXI.Application({
      resizeTo: parentElement,
      width,
      height,
    });
    parentElement.appendChild(this.app.view);

    if (!alreadyGameOver) {
      this.app.ticker.add(this.animate);
    } else {
      this.isGameOver = true;
    }

    this.sizeElements();
    this.loadAsync();
  }

  sizeElements = () => {
    Settings.width = this.app.renderer.width;
    Settings.pipeScorePosition = -(Settings.width! - Settings.playerHorizontalPosition);
    Settings.height = this.app.renderer.height;
    Settings.skyHeight = Settings.height! - Settings.groundHeight;
    Settings.pipeHorizontalGap = Settings.pipeWidth * (Game.isSmall ? 4 : 5);
  };

  resize = (width: number, height: number) => {
    this.app.renderer.resize(width * scale, height * scale);
    this.app.view.style.width = width + 'px';
    this.app.view.style.height = height + 'px';
    this.sizeElements();
  };

  loadAsync = async () => {
    this.textures = await GameUtils.initSpriteSheet(spriteSheet, sprites);
    this.onAssetsLoaded();
  };

  onAssetsLoaded = () => {
    this.background = new Background(this.textures['background']);
    this.pipeContainer = new PipeContainer(this.textures['pipe'], this.textures['finalPipe']);
    this.pipeContainer.onAddDoctor = (x: number, y: number) => {
      return new Doctor(
        [
          // this.textures['doctor1'],
          this.textures['doctor2'],
          this.textures['doctor3'],
        ],
        x,
        y,
      );
    };
    this.ground = new Ground(this.textures['ground']);
    this.pilly = new Pilly([
      this.textures['pill1'],
      this.textures['pill2'],
      this.textures['pill3'],
      this.textures['pill2'],
    ]);

    [this.background, this.pipeContainer, this.ground, this.pilly].map((child) =>
      this.app.stage.addChild(child),
    );

    this.stopAnimating = false;
  };

  onPress = () => {
    if (this.isGameOver) return;
    if (this.isAnimatingFinal) return;
    if (this.isDead) {
      this.restart();
    } else {
      this.beginGame();
    }
  };

  onGameOver = () => {
    this.isGameOver = true;
  };

  beginGame = () => {
    if (!this.isStarted) {
      this.isStarted = true;
      this.score = 0;
      this.onScore(this.score);
      this.pipeContainer!.addNewPipe();
    }
    if (this.pilly!.position.y >= 0) {
      this.pilly!.speedY = Settings.playerFallSpeed;
    }
  };

  animate = () => {
    if (this.stopAnimating) {
      this.app.ticker.remove(this.animate);
      return;
    }

    if (!this.isDead) {
      if (Math.abs(this.ground!.tilePosition.x) > this.ground!.width) {
        this.ground!.tilePosition.x = 0;
      }
      this.ground!.tilePosition.x -= Settings.gameSpeed;
    }

    if (this.isStarted) {
      this.pilly!.updateGravity();
    }

    if (this.isDead) {
      this.pilly!.rotation += Math.PI / 4;
      if (
        this.pilly!.rotation > Math.PI / 2 &&
        this.pilly!.position.y > Settings.skyHeight! - this.pilly!.height / 2
      ) {
        this.stopAnimating = true;
      }
    } else {
      if (this.pilly!.position.y + this.pilly!.height / 2 > Settings.skyHeight!) {
        this.hitPipe();
      }

      const points = this.pipeContainer!.moveAll();
      if (points) {
        this.score += points;
        this.onScore(this.score);
      }
      this.pipeContainer!.tryAddingNewPipe();

      for (const group of this.pipeContainer!.pipes) {
        const {pipe, pipe2} = group;
        if (
          this.score >= Settings.maxPipes &&
          !pipe2 &&
          this.pipeContainer?.finalPipe &&
          GameUtils.tipIntersect(this.pilly!, pipe)
        ) {
          group.price.animate();
          this.madeItHome();
        }
        if (
          GameUtils.rectIntersect(this.pilly!, pipe) ||
          (pipe2 && GameUtils.rectIntersect(this.pilly!, pipe2))
        ) {
          this.hitPipe();
        }
      }
    }
  };

  restart = () => {
    const wasAnimating = this.stopAnimating;
    this.isStarted = false;
    this.isDead = false;
    this.stopAnimating = false;
    this.score = 0;
    this.onScore(this.score);
    this.pilly!.restart();
    Settings.gameSpeed = Game.isSmall ? 5 : 6;
    this.pipeContainer!.restart();
    if (wasAnimating) {
      this.app.ticker.add(this.animate);
    }
  };

  hitPipe = () => {
    this.pilly!.stop();
    this.isDead = true;
    this.onUpdateStats?.(this.score);
  };

  madeItHome = () => {
    this.pilly!.stop();
    this.stopAnimating = true;
    this.isAnimatingFinal = true;
    this.isDead = true;
    this.pilly!.spinOut(() => {
      this.isAnimatingFinal = false;
      this.onUpdateStats?.(this.score);
    });
  };

  onScore = (score: number) => {
    if (score >= 25) {
      Settings.gameSpeed = Game.isSmall ? 5.5 : 6.5;
    } else if (score >= 50) {
      Settings.gameSpeed = Game.isSmall ? 6 : 7;
    } else if (score >= Settings.maxPipes) {
      Settings.gameSpeed = 4;
    }
    this.onUpdateScore?.(score);
  };
}
