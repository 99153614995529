import {ModalContainer} from '../ModalContainer/ModalContainer';
import {Stats} from '../../game/models';
import './GameOverModal.css';
import {memo, useCallback, useState} from 'react';
import {rewardOptions, WheelSpinner} from '../WheelSpinner/WheelSpinner';
import clhLogo from '../../assets/cleverhealth-fulllogo.svg';
import {CountDownText} from '../CountDownText/CountDownText';
import {isMobileBrowser} from '../../utils/isMobileBrowser';
import {toast} from 'react-toastify';

const POINTS_PER_SPIN = 10;

export const GameOverModal = memo(
  ({
    show,
    stats,
    updateStats,
  }: {
    show: boolean;
    stats: Stats;
    updateStats: (reward: number) => void;
  }) => {
    const [showVault, setShowVault] = useState(false);
    const remainingSpins = Math.floor(stats.totalScoreToday / POINTS_PER_SPIN) - stats.spinsToday;

    const onUpdateVault = useCallback((rewardIdx: number) => {
      const reward = rewardOptions[rewardIdx].value;
      updateStats(reward);
      toast(`You earned ${reward} point${reward === 1 ? '' : 's'}!`);
    }, []);

    const onShare = useCallback(async () => {
      const data = {
        text: `💊 Pilly survived ${stats.totalScoreToday} bottles today 💊`,
      };
      if (isMobileBrowser() && navigator.canShare(data)) {
        await navigator.share(data);
      } else {
        await navigator.clipboard.writeText(data.text);
        toast('copied to clipboard');
      }
    }, [stats]);

    return (
      <ModalContainer
        show={show}
        outerStyle={{
          maxHeight: showVault || stats.totalScoreToday < POINTS_PER_SPIN ? '500px' : '900px',
        }}
      >
        {showVault ? (
          <>
            <button
              className={'gameover__redBtn gameover__vaultBtn'}
              onClick={() => setShowVault(false)}
            >
              <div className={'gameover__redBtn-inner'}>
                <svg viewBox="0 0 16 16">
                  <path
                    fillRule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  />
                </svg>
                go back
              </div>
            </button>
            <h2 className={'gameover__title'}>
              You have {stats.rewardVault} unclaimed reward points stored in your vault!
            </h2>
            <h3 className={'gameover__title'}>
              To redeem your rewards (coming soon!), download the Clever Health app
            </h3>
            <a href={'https://join.cleverhealth.ai'} target={'_blank'}>
              <img src={clhLogo} className={'gameover__clhLogo'} />
            </a>
          </>
        ) : (
          <>
            <div className={'gameover__headerBtns'}>
              <button className={'gameover__redBtn gameover__timer'}>
                <div className={'gameover__redBtn-inner'}>
                  <svg viewBox="0 0 16 16">
                    <path d="M6 .5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1H9v1.07a7.001 7.001 0 0 1 3.274 12.474l.601.602a.5.5 0 0 1-.707.708l-.746-.746A6.97 6.97 0 0 1 8 16a6.97 6.97 0 0 1-3.422-.892l-.746.746a.5.5 0 0 1-.707-.708l.602-.602A7.001 7.001 0 0 1 7 2.07V1h-.5A.5.5 0 0 1 6 .5zm2.5 5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5zM.86 5.387A2.5 2.5 0 1 1 4.387 1.86 8.035 8.035 0 0 0 .86 5.387zM11.613 1.86a2.5 2.5 0 1 1 3.527 3.527 8.035 8.035 0 0 0-3.527-3.527z" />
                  </svg>
                  NEXT GAME
                  <CountDownText />
                </div>
              </button>
              <button
                className={'gameover__redBtn gameover__vaultBtn'}
                onClick={() => setShowVault(true)}
              >
                <div className={'gameover__redBtn-inner'}>
                  <svg viewBox="0 0 16 16">
                    <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                  </svg>
                  my reward vault
                </div>
              </button>
            </div>
            <h2 className={'gameover__title'}>
              Wow, your highest score today was {stats.highScoreToday}, and your total score today
              was {stats.totalScoreToday}!
            </h2>
            <button className={'gameover__shareBtn gameover__redBtn'} onClick={onShare}>
              <div className={'gameover__redBtn-inner'}>
                share
                <svg viewBox="0 0 16 16">
                  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                </svg>
              </div>
            </button>
            {stats.totalScoreToday >= POINTS_PER_SPIN ? (
              <>
                <h3 className={'gameover__title'}>
                  For every 10 points, you can spin the wheel for a chance at a reward!
                </h3>
                <h3 className={'gameover__title'}>
                  You have <b>{remainingSpins}</b> wheel spin{remainingSpins === 1 ? '' : 's'}{' '}
                  remaining
                </h3>
                <WheelSpinner onSpin={onUpdateVault} isDisabled={remainingSpins <= 0} />
              </>
            ) : (
              <>
                <h3 className={'gameover__title'}>
                  Come back tomorrow and reach a score of 10 or more for a chance to spin the
                  rewards wheel!
                </h3>
              </>
            )}
          </>
        )}
      </ModalContainer>
    );
  },
);
